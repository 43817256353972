<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.injection.title.name')"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout>
    <!-- <el-container class="new-sino-box"> -->
      <!-- <el-header class="new-sino-header" style="height: 45px; line-height: 40px;"> -->
        <div style="display: flex;" class="search-form">
          <el-input :placeholder="$t('cip.dc.injection.msg.placeholder')" v-model="queryList.param1" class="new-sino-input" size="mini" style="width: 200px;" />

          <el-button-group style="margin-left: 10px;">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
         </el-button-group>
        </div>
<!--        <div>-->
<!--          <el-button size="mini" type="primary" @click="addFn">新增</el-button>-->
<!--          <el-button size="mini" type="danger" @click="deleteFn">删除</el-button>-->
<!--        </div>-->

      <!-- </el-header> -->
      <div class="new-sino-main" style="padding:0 12px;background:#fff">
        <el-table
          v-tableHe
          size="mini"
          v-loading="loading"
          :element-loading-text="$t('cip.dc.injection.field.loading')"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          border
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tablename"
            :label="$t('cip.dc.injection.field.tablename')"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="keycolumn"
            :label="$t('cip.dc.injection.field.keycolumn')"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            :label="$t('cip.dc.Token.field.operation')"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">{{$t('cip.dc.Token.field.edit')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right;background: #fff;padding: 10px 0;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryList.query.current"
            :page-size="queryList.query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    <!-- </el-container> -->

  </div>
</template>

<script>
import {
  injectionList,
  injectionUp,
  injectionDel, dataOutDel
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:20,
        },
        selectVal: "",
      },
      formLabelWidth: '120px',
      dialogFormVisible:false,
      loading:false,
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      injectionList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.records
        this.total = obj.total
      });
    },
    addFn(){
      this.$router.push({
        path: '/dataHandle/injectionAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(index,item){
      this.$router.push({
        path: '/dataHandle/injectionAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
      localStorage.setItem('injectEditObj',JSON.stringify(item))
    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error(this.$t('cip.dc.injection.msg.DeleteSucceeded'))
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return Number(item.id)
      })
      this.$confirm(this.$t('cip.dc.injection.msg.isDelect'),this.$t('injection.tip') , {
        confirmButtonText: this.$t('injection.confirmButtonText'),
        cancelButtonText: this.$t('injection.cancelButtonText'),
        type: 'warning'
      }).then(() => {
        injectionDel({ids}).then((res)=>{
          this.$message.success(this.$t('cip.dc.injection.msg.DeleteSucceeded'));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    saveFn(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.id){
            injectionUp(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.injection.msg.ModifiedSuccessfully'));
              this.dialogFormVisible = false;
              this.onLoad()
            })
          }else{
            injectionUp(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.injection.msg.SuccessfullyAdded'));
              this.dialogFormVisible = false;
              this.onLoad()
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    czFn(){
      this.queryList = {
        query:{
          current:1,
          size:10,
        },
        param1: "",
      }
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.queryList.size = val;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.queryList.page = val;
      this.onLoad()
    },
  },
  created() {
    this.onLoad()
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
::v-deep .el-table{
    height:calc(100vh - 274px) !important;
  }
  //修改表格边框颜色
   ::v-deep .el-table thead tr, .el-table thead tr th{
  background: #fafafa !important;
}
::v-deep .el-table--border, .el-table--group{
  border-color: #cccccc !important;
}
::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
  border-color: #cccccc !important;
}
::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
  border-color: #cccccc !important;
}
::v-deep .el-table--border th.el-table__cell, .el-table__fixed-right-patch{
  border-color: #cccccc !important;
}
::v-deep .el-table__fixed-right::before {
  background-color: #ffffff !important;
}
::v-deep .el-table__fixed::before{
  background-color: #ffffff !important;
}
::v-deep .el-table--border::after, .el-table--group::after, .el-table::before{
  background-color: #cccccc !important;
}
::v-deep .el-table--border{
  border-bottom: 1px solid #cccccc;
}
::v-deep .el-table--mini .el-table__cell{
  padding: 0 !important;
}
</style>
<style>
.el-button+.el-button {
  margin-left: 0px;
}
</style>
